import {
  eResponseCode,
  IHTTPService,
  HTTPService,
  eHTTPMethods,
  UserService as FELUserService,
  IApplication,
  defaultApiHeaders
} from "front-end-lib";
import {
  ISigninResponse,
  IForgotPasswordResponse,
  IConfirmForgotPasswordRequest,
  IUserChallengeRequest,
  IJobStatusDTO
} from "../model/interfaces";

export class UserService extends FELUserService {
  private readonly jobsHTTPService: IHTTPService;
  private readonly _loginApiUrl: string;
  constructor(
    loginApiUrl: string,
    jobsApiUrl: string,
    applicationName: string
  ) {
    super(loginApiUrl, window.location.href, applicationName, () =>
      console.error("User is not authorized to acess the Login API.")
    );
    this._loginApiUrl = loginApiUrl;
    this.jobsHTTPService = new HTTPService(
      jobsApiUrl,
      loginApiUrl,
      applicationName,
      () => console.error("User is not authorized to acess the Jobs API.")
    );
  }

  signInWithCodeAsync = async (
    code: string,
    state: string
  ): Promise<ISigninResponse> => {
    return await this.httpService.callApi(
      eHTTPMethods.GET,
      `/signin?code=${code}&state=${state}`
    );
  };

  signInAsync = async (
    emailOrUserName: string,
    password: string
  ): Promise<ISigninResponse> => {
    emailOrUserName = encodeURIComponent(emailOrUserName);
    const login = { emailOrUserName, password: password };
    return await this.httpService.callApi(eHTTPMethods.POST, "/signin", login);
  };

  inviteNewUserAsync = async (email: string): Promise<eResponseCode> =>
    await this.httpService.callApi(eHTTPMethods.POST, "user/invite", {
      email: email
    });

  getSignInUrl = async (emailOrUserName: string): Promise<string> => {
    const res = await fetch(
      `${this._loginApiUrl}/signin/url/${encodeURIComponent(emailOrUserName)}`
    );
    return await res.text();
  };

  forgotPasswordAsync = async (
    email: string
  ): Promise<IForgotPasswordResponse> =>
    await this.httpService.callApi(
      eHTTPMethods.POST,
      `/user/${email}/password/forgot`
    );

  confirmForgotPasswordAsync = async (
    email: string,
    request: IConfirmForgotPasswordRequest
  ): Promise<string> =>
    await this.httpService.callApi(
      eHTTPMethods.POST,
      `/user/${email}/password/confirm`,
      request
    );

  challengeResponseAsync = async (
    cognitoUsername: string,
    email: string,
    request: IUserChallengeRequest
  ): Promise<ISigninResponse> => {
    await fetch(`${this._loginApiUrl}/user/${cognitoUsername}/challenge`, {
      method: "POST",
      body: JSON.stringify(request),
      headers: {
        "cache-control": "no-cache",
        "content-type": "application/json",
        pragma: "no-cache"
      }
    });
    return await this.signInAsync(email, request.password);
  };

  getRunningJobs = async (
    cognitoUsername: string
  ): Promise<IJobStatusDTO[]> => {
    return (await this.jobsHTTPService.callApi(
      eHTTPMethods.GET,
      `/user/${cognitoUsername}/jobs/running`
    )) as IJobStatusDTO[];
  };

  confirmEmailAsync = async (
    username: string,
    email: string,
    confirmEmail: string
  ): Promise<ISigninResponse> => {
    const path = `/user/${username}/email/confirm`;
    const init = {
      method: eHTTPMethods.POST,
      headers: defaultApiHeaders,
      credentials: "include",
      body: JSON.stringify({
        email: email,
        emailConfirm: confirmEmail
      })
    } as RequestInit;

    const response = await fetch(this._loginApiUrl + path, init);
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error(await response.text());
    }
  };

  waitForJobs = async (cognitoUsername: string): Promise<IJobStatusDTO> => {
    try {
      return await this.jobsHTTPService.callApi(
        eHTTPMethods.GET,
        `/user/${cognitoUsername}/jobs/wait`
      );
    } catch (e) {
      throw e;
    }
  };
}
