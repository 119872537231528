import { ValidationItem } from "./ValidationItem";
import { MAX_TEXT_LENGTH } from "../../constants";

export class Validations {
  public static required = new ValidationItem(
    (eventValue: string = "") => !!eventValue.trim(),
    "This field is required"
  );
  public static valid = new ValidationItem(() => true, "");

  public static usernameEmailAllowedCharacters = new ValidationItem(
    (eventValue: string = "") => {
      return (
        RegExp("^[-a-zA-Z0-9@&!#$%&'*+/=?^_`{|}~.]+$").test(eventValue) ||
        eventValue.length === 0
      );
    },
    "Invalid characters in entry"
  );

  public static validEmailWithSpaces = new ValidationItem(
    (eventValue: string = "") => {
      // this validates the email format but deviates from the standard in that
      // it ignores leading and trailing spaces
      return (
        /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(
          eventValue.trim()
        ) || eventValue.length === 0
      );
    },
    "Enter a valid email address"
  );

  public static validVerificationCode = new ValidationItem(
    (eventValue: string = "") => {
      // right number of digits?
      return (
        /^\d{6}$/.test(eventValue.trim()) || eventValue.trim().length === 0
      );
    },
    `Must be a ${MAX_TEXT_LENGTH.VERIFICATION_CODE} digit code`
  );

  public static validPassword = new ValidationItem(
    // must be at least 8 characters long,
    // contain 1 capital letter,
    // and one number or symbol
    (eventValue: string = "") => {
      const password = eventValue.trim();
      return (
        (password.length > 7 && // at least 8 chars and...
        /^(?=.*[A-Z])/.test(password) && // a uppercase letter and...
          (/^(?=.*[0-9])/.test(password) || // a number or...
            //symbol
            /^(?=.*[@$!%*#?&~!@#$%^&*()_+`\-{}|[\];:'"<>,.?])/.test(
              password
            ))) ||
        eventValue.trim().length === 0
      );
    },
    "Password entered does not meet the minimum requirements"
  );

  public static passwordsMatch = (password: string) => {
    return new ValidationItem((eventValue: string = "") => {
      return eventValue.trim() === password.trim();
    }, "Passwords entered do not match");
  };

  public static emailsMatch = (password: string) => {
    return new ValidationItem((eventValue: string = "") => {
      return eventValue.trim() === password.trim();
    }, "The email addresses entered do not match.");
  };
}
