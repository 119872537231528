import React, { useEffect, useContext, useState, useReducer } from "react";
import { SingletonSvcsContext, AppContextProvider } from "./contexts";
import { migrationReducer } from "./reducers/migrationReducer";
import { IAppContext, eMigrationStatus, ILoginUser } from "./model";
import { ConfigOptions } from "./constants";
import { SsoMigration } from "./components";
import { Routes } from "./routes";
import logo_large from "./assets/images/Signant-logo-lg.png";
import { FelNotification, FelProgressOverlay, FelFooter } from "front-end-lib";

import "./_main.scss";

export const Main = () => {
  const { user } = useContext(SingletonSvcsContext);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState({} as ILoginUser);
  const [renderLoadingModal, setRenderLoadingModal] = useState(false);
  const [state, dispatch] = useReducer(migrationReducer, {
    status: eMigrationStatus.None,
    message: ""
  });
  const appContext: IAppContext = {
    email: email,
    username: username,
    password: password,
    loggedInUser: loggedInUser,
    setAppEmail: (val) => {
      setEmail(val);
    },
    setAppUsername: (val) => {
      setUsername(val);
    },
    setAppPassword: (val) => {
      setPassword(val);
    },
    setAppLoggedInUser: (val) => {
      setLoggedInUser(Object.assign(loggedInUser, val));
    },
    displayNotification: (val: string) => {
      setSnackbarOpen(true);
      setSnackbarMessage(val);
    },
    renderLoadingModal: (val: boolean) => {
      setRenderLoadingModal(val);
    },
    migrationReducer: { state, dispatch }
  };

  useEffect(() => {
    if (!window.location.pathname.includes("new-password")) {
      (async () => {
        try {
          setRenderLoadingModal(true);
          const loggedInUser = await user.getLoggedInUserAsync();
          if (loggedInUser) {
            window.location.href = ConfigOptions.userPortalUrl;
          } else {
            setRenderLoadingModal(false);
          }
        } catch (e) {
          console.error(e);
          setRenderLoadingModal(false);
        }
      })();
    } else {
      dispatch({ type: "complete" }); // no migration necessary, and no auth at this point yet either
    }
    // eslint-disable-next-line
  }, []);

  return (
    <AppContextProvider value={appContext}>
      <SsoMigration
        status={state.status || eMigrationStatus.None}
        message={state.message || ""}
      />
      <FelProgressOverlay inProgress={renderLoadingModal} />
      <div className="router-container">
        <div className="router-container--section--placeholder"></div>
        <div id="logo">
          <img
            className="router-container--logo"
            src={logo_large}
            alt="Signant Health"
          />
        </div>
        <div className="router-container--section--contents">
          <span className="router-container--section--contents--layout">
            <Routes />
          </span>
        </div>
        <div className="router-container--section--help">
          <a
            href={ConfigOptions.helpUrl}
            className="router-container--section--help--text"
            target="_blank"
            rel="noopener noreferrer"
          >
            Need Help?
          </a>
        </div>
        <FelNotification
          snackbarProps={{
            open: snackbarOpen,
            onClose: () => setSnackbarOpen(false)
          }}
          alertProps={{ onClose: () => setSnackbarOpen(false) }}
        >
          {snackbarMessage}
        </FelNotification>
        <FelFooter />
      </div>
    </AppContextProvider>
  );
};
