import React, { useContext, useEffect } from "react";
import { CircularProgress, Button, FelModal } from "front-end-lib";
import { ConfigOptions } from "../../../constants";
import { SingletonSvcsContext, AppContext } from "../../../contexts";
import { eMigrationStatus } from "../../../model";
import { useHistory } from "react-router";

import "./_sso-migration.scss";

export const SsoMigration = (props: {
  status: eMigrationStatus;
  message: string;
}) => {
  const { status, message } = props;
  const { user } = useContext(SingletonSvcsContext);
  const {
    password,
    username,
    loggedInUser,
    renderLoadingModal,
    migrationReducer: { dispatch: dispatchMigration }
  } = useContext(AppContext);

  const history = useHistory();

  const dispatchCompleteMigration = () => {
    dispatchMigration({
      type: "complete",
      payload: {
        message: "The account has been configured successfully."
      }
    });
  };

  const completeMigrationProcess = async () => {
    try {
      if (loggedInUser.externalProvider) {
        var response = await user.getSignInUrl(loggedInUser.cognitoUserName);
        dispatchCompleteMigration();
        window.location.href = response;
        return;
      } else {
        var responseExternal = await user.signInAsync(
          username.trim(),
          password.trim()
        );
        if (responseExternal.isMigrating) {
          renderLoadingModal(false);
          dispatchMigration({ type: "error" });
        } else {
          dispatchCompleteMigration();
          history.push("/verify-email", {
            cognitoUsername: responseExternal.cognitoUserName,
            email: responseExternal.credential
          });
        }
        return;
      }
    } catch (e) {
      dispatchMigration({ type: "error" });
      throw e;
    }
  };

  useEffect(() => {
    switch (status) {
      case eMigrationStatus.Checking:
        renderLoadingModal(true);
        user
          .getRunningJobs(loggedInUser.cognitoUserName)
          .then((jobs) => {
            if (jobs.length > 0) {
              dispatchMigration({
                type: "wait",
                payload: { username: loggedInUser.cognitoUserName }
              });
            } else {
              completeMigrationProcess();
            }
          })
          .catch((e) => {
            console.error(e);
          });
        break;
      case eMigrationStatus.Waiting:
        renderLoadingModal(false);
        user
          .waitForJobs(loggedInUser.cognitoUserName)
          .then(() => {
            completeMigrationProcess();
          })
          .catch((e) => {
            dispatchMigration({ type: "error" });
          });
    }
    // eslint-disable-next-line
  }, [status]);

  const renderHeader = () => {
    return (
      <p id="pMigrationHeader" data-testid="pMigrationHeaderTest">
        {status === eMigrationStatus.Error ? "Oops!" : "One Moment Please..."}
      </p>
    );
  };

  const renderErrorMessage = () => (
    <p id="pMigrationError" data-testid="pMigrationErrorTest">
      Your data could not be retrieved. Please{" "}
      <a
        id="aMigrationError"
        data-testid="aMigrationError"
        href={ConfigOptions.signantSupportUrl}
      >
        contact
      </a>{" "}
      our technical support team.
    </p>
  );

  const renderContent = () => {
    return (
      <div
        id="divMigrationContent"
        data-testid="divMigrationContentTest"
        className="sso-migration--div__content"
      >
        {status === eMigrationStatus.Error ? (
          renderErrorMessage()
        ) : (
          <p id="pMigrationContent" data-testid="pMigrationContentTest">
            {message}
          </p>
        )}
        {status === eMigrationStatus.Waiting ? (
          <CircularProgress
            id="cpMigrationContent"
            data-testid="cpMigrationContentTest"
          />
        ) : null}
      </div>
    );
  };

  const renderActions = () => {
    return status === eMigrationStatus.Error ? (
      <div id="divMigrationActions" data-testid="divMigrationActionsTest">
        <Button
          id="btnMigrationClose"
          data-testid="btnMigrationCloseTest"
          onClick={() => dispatchMigration({ type: "complete" })}
        >
          Close
        </Button>
      </div>
    ) : null;
  };

  return status === eMigrationStatus.Waiting ||
    status === eMigrationStatus.Error ? (
    <FelModal
      header={renderHeader()}
      content={renderContent()}
      actions={renderActions()}
      autoFocus={true}
    />
  ) : null;
};
