import React from "react";
import {
  BktTextFieldProps,
  BktTextField,
  BktValidatedTextField,
  IValidationItem
} from "front-end-lib";

import "./_sso-textfield.scss";

interface SsoLabelProps {
  labelText?: string;
}

export interface SsoTextFieldProps {
  id?: string;
  textFieldProps: BktTextFieldProps;
  labelProps?: SsoLabelProps;
  validationForOnChange?: IValidationItem[];
  validationForOnBlur?: IValidationItem[];
  validationerror?: (hasError: boolean) => void;
}

export const SsoTextField = (props: SsoTextFieldProps) => {
  const {
    id = "sso-textfield",
    labelProps = {},
    textFieldProps,
    validationForOnChange,
    validationForOnBlur,
    validationerror
  } = props;

  // add the input field className if there wasn't one passed in
  const textFldProps = Object.assign(
    {},
    { ...textFieldProps },
    {
      textProps: {
        margin: "dense",
        variant: "outlined",
        ...textFieldProps.textProps,
        InputProps: Object.assign(
          {},
          { className: "sso-textfield--input" },
          { ...textFieldProps.textProps.InputProps }
        )
      }
    }
  );

  return (
    <React.Fragment>
      <div
        data-testid="textfield-label"
        className={`${id}--label`}
        id={`${id}--label`}
      >
        {labelProps.labelText || ""}
      </div>
      <span className={`${id}--container`} id={`${id}--container`}>
        {!validationForOnChange && !validationForOnBlur ? (
          <span
            data-testid="textfield"
            id={`${id}--textfield-wrapper`}
            className={`${id}--textfield-wrapper`}
          >
            <BktTextField {...textFldProps}></BktTextField>
          </span>
        ) : (
          <span
            data-testid="validated-textfield"
            id={`${id}--validated-textfield-wrapper`}
            className={`${id}--validated-textfield-wrapper`}
          >
            <BktValidatedTextField
              validationForOnChange={validationForOnChange}
              validationForOnBlur={validationForOnBlur}
              validationerror={validationerror}
              textFieldProps={textFldProps}
            />
          </span>
        )}
      </span>
    </React.Fragment>
  );
};
