import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { ArrowBackIcon, FelProgressButton } from "front-end-lib";
import { Grid, IconButton, Box, Paper } from "@material-ui/core";
import { SsoTextField, SsoPasswordAdornment } from "../common";
import { AppContext, SingletonSvcsContext } from "../../contexts";

import { CHALLENGES } from "../../constants";
import SigninHelper from "./SigninProcessor";
import "./_password.scss";
import { ILoginUser } from "../../model";

export const Password = () => {
  const history = useHistory();

  const [viewPassword, setViewPassword] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const [inProgress, setInProgress] = React.useState(false);

  const {
    displayNotification: openSnackbar,
    username,
    // email,
    migrationReducer,
    setAppPassword,
    setAppLoggedInUser
  } = useContext(AppContext);
  const { user } = useContext(SingletonSvcsContext);

  React.useEffect(() => {
    if (!username) {
      // If no username (they probably went directly to this page before the username page), send them
      // back where they belong.
      history.push("/");
    }
    // eslint-disable-next-line
  }, []);

  const isButtonDisabled = inProgress || !password.trim().length;

  const onNext = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setInProgress(true);
    try {
      const signinResponse = await user.signInAsync(
        username.trim(),
        password.trim()
      );

      setInProgress(false);

      if (signinResponse.isMigrating) {
        setAppPassword(password.trim());
        setAppLoggedInUser({
          cognitoUserName: signinResponse.cognitoUserName,
          email: signinResponse.credential,
          externalProvider: signinResponse.externalProvider
        } as ILoginUser);

        migrationReducer.dispatch({ type: "check" });
        return;
      }

      if (signinResponse.challengeRequired) {
        switch (signinResponse.challengeNameType) {
          case CHALLENGES.CONFIRM_EMAIL_REQUIRED:
            history.push("/verify-email", {
              cognitoUsername: signinResponse.cognitoUserName,
              email: signinResponse.credential
            });
            return;
          case CHALLENGES.NEW_PASSWORD_REQUIRED:
            const {
              cognitoUsername,
              email
            } = SigninHelper.handleNewPasswordRequired(signinResponse);
            history.push(
              `/new-password?cognitoUserName=${cognitoUsername}&email=${email}`
            );
            return;
          case CHALLENGES.PASSWORD_EXPIRED:
            setInProgress(true);
            try {
              await user.forgotPasswordAsync(
                encodeURIComponent(signinResponse.credential)
              );
              setInProgress(false);
              history.push({
                pathname: "/create-password",
                state: { verification: true, expired: true }
              });
              return;
            } catch (ex) {
              setInProgress(false);
              openSnackbar(`Error occurred sending request`);
            }
            return;
          default:
            break;
        }
      }

      window.location.href = signinResponse.redirectUrl;
    } catch (e) {
      setInProgress(false);
      console.error(`${e.message}, ${e.responseMessage}`);
      openSnackbar(e.responseMessage);
      return;
    }
  };

  return (
    <Paper elevation={2} className="paper paper-password">
      <form onSubmit={() => onNext}>
        <Box display="flex" justifyContent="center" className="signin-logo">
          <h1>Sign In</h1>
        </Box>
        <Grid container direction="column" spacing={2}>
          <div
            id="passwordBackButton"
            className="sso-password--back"
            data-testid="divBack"
          >
            <IconButton
              onClick={(e) => history.push("/")}
              id="btnBack"
              data-testid="btnBack"
            >
              <ArrowBackIcon id="iconBack" data-testid="iconBack" />
              <div className="sso-password--username" data-testid="divUsername">
                {username}
              </div>
            </IconButton>
          </div>
          <Grid item xs={12} data-testid="gridItemPassword">
            <SsoTextField
              labelProps={{ labelText: "Password" }}
              textFieldProps={{
                debounceInterval: 0,
                textProps: {
                  id: "txtPassword",
                  placeholder: "Enter password",
                  autoFocus: true,
                  value: password,
                  type: viewPassword ? "text" : "password",
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                    setPassword(e.target.value),
                  InputProps: {
                    endAdornment: isButtonDisabled ? null : (
                      <SsoPasswordAdornment
                        viewSetter={setViewPassword}
                        showPassword={viewPassword}
                      />
                    ),
                    disabled: inProgress
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FelProgressButton
              buttonProps={{
                disabled: isButtonDisabled,
                type: "submit",
                className: "btn next-btn",
                variant: "contained",
                color: "primary",
                onClick: onNext
              }}
              inProgress={inProgress}
            >
              Sign In
            </FelProgressButton>
          </Grid>
          <Grid item xs={12}>
            <div
              id="linkForgotPassword"
              className="sso-password--forgotLink"
              onClick={(e) => history.push("/reset-password")}
            >
              Forgot Password?
            </div>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
