import React, { ChangeEvent, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Grid, Box, Paper } from "@material-ui/core";
import { FelProgressButton } from "front-end-lib";
import { INewUserAction, Validations } from "../../model";
import { MAX_TEXT_LENGTH } from "../../constants";
import { SsoTextField } from "../common";
import { AppContext, SingletonSvcsContext } from "../../contexts";

import "./_username.scss";

type UsernameProps = Partial<INewUserAction>;

export const Username = (props: UsernameProps) => {
  const history = useHistory();

  const {
    username,
    setAppUsername,
    setAppEmail,
    displayNotification: openSnackbar
  } = useContext(AppContext);
  const { user } = useContext(SingletonSvcsContext);

  const [hasValidationError, setHasValidationError] = React.useState(false);
  const [email, setEmail] = React.useState(username);
  const [inProgress, setInProgress] = React.useState(false);

  const buttonIsDisabled = !email.trim() || hasValidationError || inProgress;

  const onNextClick = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setInProgress(true);

    try {
      const signInUrl = await user.getSignInUrl(email.trim());
      if (signInUrl) {
        window.location.replace(signInUrl);
      } else {
        setInProgress(false);
        setAppUsername(email.trim());
        setAppEmail(email.trim());
        history.push("/password");
      }
    } catch (e) {
      setInProgress(false);
      console.error(e);
      openSnackbar(`Unable to retrieve sign in url.`);
    }
  };

  return (
    <Paper elevation={2} className="paper paper-signin">
      <form onSubmit={() => onNextClick}>
        <Box display="flex" justifyContent="center" className="signin-logo">
          <h1>Sign In</h1>
        </Box>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <SsoTextField
              labelProps={{ labelText: "Username" }}
              validationForOnBlur={[Validations.usernameEmailAllowedCharacters]}
              validationerror={(validationError: boolean) =>
                setHasValidationError(validationError)
              }
              textFieldProps={{
                clearable: !inProgress,
                debounceInterval: 0,
                textProps: {
                  id: "txtUsernameId",
                  placeholder: "Enter your email address",
                  autoFocus: true,
                  value: email,
                  onChange: (e: ChangeEvent<HTMLInputElement>) =>
                    setEmail(e.target.value),
                  inputProps: { maxLength: MAX_TEXT_LENGTH.EMAIL },
                  InputProps: {
                    disabled: inProgress
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FelProgressButton
              buttonProps={{
                disabled: buttonIsDisabled,
                className: "btn next-btn",
                variant: "contained",
                color: "primary",
                onClick: onNextClick,
                type: "submit"
              }}
              inProgress={inProgress}
            >
              Next
            </FelProgressButton>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};
