import { IValidationItem } from "front-end-lib";
export class ValidationItem implements IValidationItem {
  public func: (eventValue?: string) => boolean;
  public validationMessage: string;
  constructor(
    func: (eventValue?: string) => boolean,
    validationMessage: string
  ) {
    this.func = func;
    this.validationMessage = validationMessage;
  }
}
