import { UserService } from "../services";
import React from "react";
import { ConfigOptions } from "../constants";

export interface SingletonServices {
  user: UserService;
}

const SingletonContext: SingletonServices = {
  user: new UserService(
    ConfigOptions.loginApiUrl,
    ConfigOptions.jobsApiUrl,
    ConfigOptions.applicationName
  )
};

export const SingletonSvcsContext = React.createContext<SingletonServices>(
  SingletonContext
);
export const SingletonProvider = SingletonSvcsContext.Provider;
export const SingletonConsumer = SingletonSvcsContext.Consumer;
export default SingletonSvcsContext;
