import * as React from "react";

export class User {
  public username: string;
  public password: string;

  constructor(username: string = "", password: string = "") {
    this.username = username;
    this.password = password;
  }
}

interface IUserState {
  user: User;
}

const initialState = {
  user: new User()
};

export enum eUserActions {
  SET_USER = "SET_USER"
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case eUserActions.SET_USER:
      return { ...state, user: Object.assign({}, state.user, action.payload) };
  }
};

export const UserContext = React.createContext<any>(initialState);

export const UserContextProvider = (props: any) => {
  console.info("Props inside UserContextProvider: ", props);

  return (
    <UserContext.Provider value={React.useReducer(reducer, initialState)}>
      {props.children}
    </UserContext.Provider>
  );
};
