import { ISigninResponse } from "../../model";

const handleNewPasswordRequired = (
  signinResponse: ISigninResponse
): { cognitoUsername: string; email: string } => {
  const userChallegeAttrs = JSON.parse(
    signinResponse.challengeParameters.userAttributes
  ) as { email: string };
  const cognitoUsername = (signinResponse.cognitoUserName = encodeURIComponent(
    signinResponse.cognitoUserName
  ));
  const email = encodeURIComponent(userChallegeAttrs.email);
  return { cognitoUsername, email };
};

export default {
  handleNewPasswordRequired: handleNewPasswordRequired
};
