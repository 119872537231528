import * as React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Username,
  Password,
  CreateNewPassword,
  ForgotPassword,
  InitialPassword,
  VerifyEmail,
  VerifyEmailPassword
} from "../components";

export const Routes = () => (
  <Switch>
    <React.Fragment>
      <Route exact component={Username} path="/"></Route>
      <Route exact component={Password} path="/password"></Route>
      <Route exact component={ForgotPassword} path="/reset-password"></Route>
      <Route
        exact
        component={CreateNewPassword}
        path="/create-password"
      ></Route>
      <Route exact component={InitialPassword} path="/new-password" />
      <Route exact component={VerifyEmail} path="/verify-email"></Route>
      <Route
        exact
        component={VerifyEmailPassword}
        path="/verify-email-password"
      ></Route>
    </React.Fragment>
  </Switch>
);
