import { ConfigHelper } from "../config";

export class ConfigOptions {
  public static readonly helpUrl = "https://www.signanthealth.com/support/";
  public static readonly privacyPolicyUrl =
    "https://www.signanthealth.com/privacy-policy/";
  public static readonly termsOfServiceUrl =
    "https://www.signanthealth.com/terms-of-service/";
  public static readonly signantSupportUrl =
    "https://www.signanthealth.com/support/";

  public static readonly loginApiUrl = `https://${
    ConfigHelper.isLocal
      ? process.env.REACT_APP_BASE_API_URL
      : `${window.location.hostname}/login/api`
  }`;

  public static readonly jobsApiUrl = `${window.location.protocol}//${
    ConfigHelper.isLocal
      ? process.env.REACT_APP_JOBS_URL || "undefined"
      : `${window.location.hostname}/jobs/api`
  }`;

  public static readonly userPortalUrl = `${window.location.protocol}//${
    ConfigHelper.isLocal
      ? process.env.REACT_APP_USER_PORTAL_URL || "undefined"
      : `${window.location.hostname}/user-portal`
  }`;

  public static readonly applicationName = "UserPortal";
}
