import { eMigrationStatus } from "../model";

export interface IMigrationState {
  username?: string;
  status?: eMigrationStatus;
  message?: string;
}

export interface IMigrationAction {
  type: "check" | "wait" | "error" | "complete";
  payload?: IMigrationState;
}

export const migrationReducer = (
  state: IMigrationState,
  action: IMigrationAction
) => {
  // console.log(action, state);
  switch (action.type) {
    case "check":
      return {
        ...state,
        ...{
          status: eMigrationStatus.Checking,
          message: "Checking to see if an account exists."
        }
      } as IMigrationState;
    case "wait":
      return {
        ...state,
        ...action.payload,
        ...{
          status: eMigrationStatus.Waiting,
          message: "Please wait while we configure your account."
        }
      } as IMigrationState;
    case "complete":
      return {
        ...state,
        ...action.payload,
        ...{ status: eMigrationStatus.Complete }
      } as IMigrationState;
    case "error":
      return {
        ...state,
        ...action.payload,
        ...{ status: eMigrationStatus.Error }
      } as IMigrationState;
    default:
      return { ...state, ...action.payload };
  }
};
