import React from "react";
import { InputAdornment, IconButton } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

import "./_sso_password-adornment.scss";

interface SsoPasswordAdornmentProps {
  showPassword: boolean;
  viewSetter: (showPassword: boolean) => void;
}

export const SsoPasswordAdornment = (props: SsoPasswordAdornmentProps) => {
  const { showPassword, viewSetter } = props;

  return (
    <InputAdornment position="end">
      <IconButton
        className="password-adornment--visibility-icon"
        aria-label="toggle password visibility"
        data-testid={showPassword ? "btnNoVisibility" : "btnVisibility"}
        tabIndex={-1}
        onClick={() => viewSetter(!showPassword)}
        disableRipple
      >
        {showPassword ? (
          <VisibilityOffIcon fontSize="small" />
        ) : (
          <VisibilityIcon fontSize="small" />
        )}
      </IconButton>
    </InputAdornment>
  );
};
