import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { BrowserRouter as Router } from "react-router-dom";
import { UserService } from "./services";
import { Main } from "./Main";
import { SingletonServices, SingletonProvider } from "./contexts";
import { ConfigOptions } from "./constants";

import { StylesProvider } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import muiTheme from "./muiArtifacts/muiTheme";

const servicesContext: SingletonServices = {
  user: new UserService(
    ConfigOptions.loginApiUrl,
    ConfigOptions.jobsApiUrl,
    ConfigOptions.applicationName
  )
};

const app = () =>
  ReactDOM.render(
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={muiTheme}>
        <Router basename="/login">
          <SingletonProvider value={servicesContext}>
            <Main />
          </SingletonProvider>
        </Router>
      </MuiThemeProvider>
    </StylesProvider>,
    document.getElementById("root")
  );

app();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
