import React, { useState, useContext, ChangeEvent } from "react";
import { useHistory } from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import { Paper, Grid, Box } from "@material-ui/core";
import { FelProgressButton } from "front-end-lib";
import { SingletonSvcsContext, AppContext } from "../../contexts";
import { COMPANY_NAME, MAX_TEXT_LENGTH } from "../../constants";
import { Validations } from "../../model";
import { SsoTextField } from "../../components";

import "./_forgotPassword.scss";

interface IForgotPasswordProps {}

export const ForgotPassword = (props: IForgotPasswordProps) => {
  const history = useHistory();
  //TODO - possibly make this a HOC?
  const { user: userSvc } = useContext(SingletonSvcsContext);
  const {
    username,
    setAppEmail,
    displayNotification: openSnackbar
  } = useContext(AppContext);

  const [email, setEmail] = useState(username);
  const [hasValidationError, setHasValidationError] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);

  React.useEffect(() => {
    if (!email) {
      // If no email (they probably went directly to this page before the username page), send them
      // back where they belong.
      history.push("/");
    }
    // eslint-disable-next-line
  }, []);

  const disableNext =
    inProgress || email.trim().length === 0 || hasValidationError;

  const onNext = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setAppEmail(email.trim());
    setInProgress(true);
    try {
      await userSvc.forgotPasswordAsync(encodeURIComponent(email.trim()));
      setInProgress(false);
      history.push({
        pathname: "/create-password",
        state: { verification: true }
      });
    } catch (ex) {
      setInProgress(false);
      openSnackbar(`Error occurred sending request`);
    }
  };

  return (
    <form onSubmit={() => onNext}>
      <Paper elevation={2} className="sso-forgot-password">
        <h1
          className="sso-forgot-password--header"
          data-testid="hdrForgotPassword"
        >
          Forgot Password
        </h1>
        <div className="sso-forgot-password--container">
          <Grid
            container
            direction="row"
            className="sso-forgot-password--email-info"
            data-testid="divInfo"
          >
            <Grid item xs={2}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Paper elevation={0}>
                  <InfoIcon
                    data-testid="svgInfo"
                    color="inherit"
                    classes={{ root: "sso-forgot-password--icon--email" }}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={10} className="sso-forgot-password--content">
              <div
                className="sso-forgot-password--content--email-text"
                data-testid="email-text"
              >
                {`Enter the email address associated to your ${COMPANY_NAME} account and we will send you a security code to verify your account.`}
              </div>
            </Grid>
          </Grid>
          <SsoTextField
            labelProps={{ labelText: "Email Address" }}
            validationForOnBlur={[Validations.validEmailWithSpaces]}
            validationerror={(validationError: boolean) =>
              setHasValidationError(validationError)
            }
            textFieldProps={{
              clearable: !inProgress,
              debounceInterval: 0,
              textProps: {
                id: "txtEmail",
                placeholder: "Enter your email address",
                autoFocus: true,
                value: email,
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.target.value),
                inputProps: { maxLength: MAX_TEXT_LENGTH.EMAIL },
                InputProps: {
                  disabled: inProgress
                }
              }
            }}
          />
          <FelProgressButton
            buttonProps={{
              type: "submit",
              disabled: disableNext,
              className: "btn next-btn sso-forgot-password--button",
              variant: "contained",
              color: "primary",
              onClick: onNext
            }}
            inProgress={inProgress}
          >
            Next
          </FelProgressButton>
        </div>
      </Paper>
    </form>
  );
};
