import React, { useState, useContext, ChangeEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowBackIcon, FelProgressButton } from "front-end-lib";
import InfoIcon from "@material-ui/icons/Info";
import { IConfirmForgotPasswordRequest, Validations } from "../../model";
import { Paper, Grid, IconButton } from "@material-ui/core";
import { SingletonSvcsContext, AppContext } from "../../contexts";
import { MAX_TEXT_LENGTH, ConfigOptions } from "../../constants";
import { SsoTextField, SsoPasswordAdornment } from "..";

import "./_verify-email-password.scss";

interface IVerifyEmailPasswordProps {
  location: {
    state: { verification?: boolean; expired?: boolean; verifyEmail?: boolean };
  };
}

export const VerifyEmailPassword = (props: IVerifyEmailPasswordProps) => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = new URLSearchParams(window.location.search);
  const cognitoUsername = urlParams.get("cognitoUserName");
  const email = urlParams.get("email");
  if (!cognitoUsername || !email) {
    // They shouldn't be here.  Send them back to the login screen.
    history.push("/"); //TODO
  }

  const { user: userSvc } = useContext(SingletonSvcsContext);

  const { displayNotification } = useContext(AppContext);

  const [emailVerify] = useState(props.location.state?.verifyEmail ?? false);
  const [verificationCode, setVerificationCode] = React.useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [hasPasswordValidationError, setHasPasswordValidationError] = useState(
    false
  );
  const [hasCodeValidationError, setHasCodeValidationError] = useState(false);

  React.useEffect(() => {
    if (!email) {
      // If no email (they probably went directly to this page before the previous ones), send them
      // back where they belong.
      history.push("/");
    }
    // eslint-disable-next-line
  }, []);

  const isVerification = props.location.state
    ? props.location.state.verification
    : false;
  const isExpired = props.location.state ? props.location.state.expired : false;

  const onSubmitClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    setInProgress(true);
    const request: IConfirmForgotPasswordRequest = {
      password: password.trim(),
      confirmedPassword: confirmPassword.trim(),
      resetCode: verificationCode.trim()
    };
    try {
      await userSvc.confirmForgotPasswordAsync(email!.trim(), request);
      await userSvc.signInAsync(email!.trim(), password.trim());
      window.location.href = ConfigOptions.userPortalUrl;
    } catch (ex) {
      if (ex.responseMessage) displayNotification(ex.responseMessage);
      else displayNotification("Failed to fetch");
    } finally {
      setInProgress(false);
    }
  };

  const isButtonDisabled =
    inProgress ||
    hasPasswordValidationError ||
    hasCodeValidationError ||
    (isVerification && !verificationCode.trim().length) ||
    !password.trim().length ||
    !confirmPassword.trim().length;

  return (
    <Paper
      elevation={2}
      className={
        isVerification && !isExpired ? "new-password" : "new-password--expired"
      }
    >
      <h1 className="new-password--header" data-testid="hdrNewPassword">
        {!isExpired ? "Create New Password" : "Password Expired"}
      </h1>
      <div className="new-password--content">
        <Grid
          container
          direction="row"
          className="new-password--info"
          data-testid="divInfo"
        >
          <Grid item xs={2}>
            <InfoIcon
              className="new-password--info--icon"
              data-testid="svgInfo"
            />
          </Grid>
          <Grid item xs={10} className="new-password--info--content">
            <div className="new-password--info--content--header">
              {isVerification && !isExpired ? (
                <span>
                  {`Please enter the ${MAX_TEXT_LENGTH.VERIFICATION_CODE}-digit code sent to your email address.`}
                </span>
              ) : (
                <span>
                  Please enter a new password as the password you entered has
                  expired. Your password has expired. A verification code has
                  been sent to your email address that you must use to verify
                  your account and reset your password.
                </span>
              )}
            </div>
            <h4>New Password:</h4>
            <ul className="new-password--ul">
              <li>Must be at least 8 characters long</li>
              <li>Contain at least 1 capital letter</li>
              <li>Contain at least 1 number or symbol</li>
            </ul>
          </Grid>
        </Grid>
        <div
          id="passwordBackButton"
          className="new-password--email"
          data-testid="divEmail"
        >
          <IconButton
            onClick={(e) => history.goBack()}
            id="btnBack"
            data-testid="btnBack"
          >
            <ArrowBackIcon id="iconBack" data-testid="iconBack" />
            <span className="new-password--email--text">{email}</span>
          </IconButton>
        </div>
        {/* <div className="new-password--email" data-testid="divEmail">
          <ArrowBackIcon id="iconBack" data-testid="iconBack" />
          <span className="new-password--email--text">{email}</span>
        </div> */}
        {isVerification && (
          <SsoTextField
            labelProps={{ labelText: "Verification Code" }}
            validationForOnBlur={[Validations.validVerificationCode]}
            validationerror={(validationError: boolean) =>
              setHasCodeValidationError(validationError)
            }
            textFieldProps={{
              clearable: !inProgress,
              debounceInterval: 0,
              textProps: {
                id: "txtCode",
                placeholder: "Enter code provided in email",
                autoFocus: isVerification,
                value: verificationCode,
                onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                  setVerificationCode(e.target.value),
                InputProps: {
                  disabled: inProgress
                }
              }
            }}
          />
        )}
        <SsoTextField
          labelProps={{ labelText: "New Password" }}
          validationForOnBlur={[Validations.validPassword]}
          textFieldProps={{
            debounceInterval: 0,
            textProps: {
              autoComplete: "new-password",
              id: "txtPassword",
              placeholder: "Enter your new password",
              autoFocus: !isVerification,
              value: password,
              type: viewPassword ? "text" : "password",
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value),
              InputProps: {
                disabled: inProgress,
                endAdornment:
                  password.length && !inProgress ? (
                    <SsoPasswordAdornment
                      viewSetter={setViewPassword}
                      showPassword={viewPassword}
                    />
                  ) : null
              }
            }
          }}
        />
        <SsoTextField
          labelProps={{ labelText: "Confirm New Password" }}
          validationForOnBlur={[
            Validations.passwordsMatch(password),
            Validations.validPassword
          ]}
          validationerror={(validationError: boolean) =>
            setHasPasswordValidationError(validationError)
          }
          textFieldProps={{
            debounceInterval: 0,
            textProps: {
              className: isExpired ? "new-password--expired--padding" : "",
              id: "txtConfirmPassword",
              autoComplete: "new-password",
              placeholder: "Confirm your new password",
              type: viewConfirmPassword ? "text" : "password",
              value: confirmPassword,
              onChange: (e: ChangeEvent<HTMLInputElement>) =>
                setConfirmPassword(e.target.value),
              InputProps: {
                disabled: inProgress,
                endAdornment:
                  confirmPassword.length && !inProgress ? (
                    <SsoPasswordAdornment
                      viewSetter={setViewConfirmPassword}
                      showPassword={viewConfirmPassword}
                    />
                  ) : null
              }
            }
          }}
        />
        <FelProgressButton
          buttonProps={{
            disabled: isButtonDisabled,
            type: "button",
            className: "btn next-btn new-password--button",
            variant: "contained",
            color: "primary",
            onClick: (e) => onSubmitClick(e)
          }}
          inProgress={inProgress}
        >
          Submit and Continue
        </FelProgressButton>
      </div>
    </Paper>
  );
};
