export const COMPANY_NAME = "Signant Health";

export const MAX_TEXT_LENGTH = {
  EMAIL: 320,
  VERIFICATION_CODE: 6
};

export const CHALLENGES = {
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  PASSWORD_EXPIRED: "PASSWORD_EXPIRED",
  CONFIRM_EMAIL_REQUIRED: "VERIFY_EMAIL"
};
