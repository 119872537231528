import React, { useState, useContext, ChangeEvent } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { FelProgressButton } from "front-end-lib";
import PersonIcon from "@material-ui/icons/Person";
import InfoIcon from "@material-ui/icons/Info";
import { Validations } from "../../model";
import { Paper, Grid } from "@material-ui/core";
import { SingletonSvcsContext, AppContext } from "../../contexts";
import { ConfigOptions } from "../../constants";
import { SsoTextField, SsoPasswordAdornment } from "../../components";

import "./_initial-password.scss";

interface IInitialPasswordProps
  extends RouteComponentProps<{}, {}, { verifyEmail: boolean }> {}

export const InitialPassword = (props: IInitialPasswordProps) => {
  const history = useHistory();
  const { user: userSvc } = useContext(SingletonSvcsContext);

  const { displayNotification } = useContext(AppContext);

  const [tempPassword, setTempPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmedPassword] = useState("");
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [hasPasswordValidationError, setHasPasswordValidationError] = useState(
    false
  );
  const [
    hasConfirmPasswordValidationError,
    setHasConfirmPasswordValidationError
  ] = useState(false);
  const [cognitoUsername, setCognitoUsername] = useState("");
  const [email, setEmail] = useState("");

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const cognitoUsername = urlParams.get("cognitoUserName");
    const email = urlParams.get("email");
    if (!cognitoUsername || !email) {
      // They shouldn't be here.  Send them back to the login screen.
      history.push("/"); //TODO
    }

    setCognitoUsername(cognitoUsername!);
    setEmail(email!);

    // eslint-disable-next-line
  }, []);

  // user that was added via user management
  const onSubmit = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    setInProgress(true);
    e.preventDefault();
    try {
      await userSvc.challengeResponseAsync(cognitoUsername, email, {
        tempPassword: tempPassword.trim(),
        password: password.trim(),
        confirmedPassword: confirmedPassword.trim()
      });
      setInProgress(false);
      window.location.href = ConfigOptions.userPortalUrl;
    } catch (ex) {
      setInProgress(false);
      console.error("Error: ", ex);
      displayNotification("Error creating password");
    }
  };

  const isButtonDisabled =
    inProgress ||
    hasPasswordValidationError ||
    hasConfirmPasswordValidationError ||
    !tempPassword.trim() ||
    !password.trim().length ||
    !confirmedPassword.trim().length;

  return (
    <Paper elevation={2} className={"initial-password--expired"}>
      <h1 className="initial-password--header" data-testid="hdrNewPassword">
        Create New Password
      </h1>
      <div className="initial-password--content">
        <Grid
          container
          direction="row"
          className="initial-password--info"
          data-testid="divInfo"
        >
          <Grid item xs={2}>
            <InfoIcon
              className="initial-password--info--icon"
              data-testid="svgInfo"
            />
          </Grid>
          <Grid item xs={10} className="initial-password--info--content">
            <div className="initial-password--info--content--header">
              <span>
                Please enter the temporary password sent to your email address.
              </span>
            </div>
            <h4>Password:</h4>
            <ul
              className={
                !hasPasswordValidationError &&
                !hasConfirmPasswordValidationError
                  ? "initial-password--info--ul"
                  : "initial-password--info--ul-red"
              }
            >
              <li>Must be at least 8 characters long</li>
              <li>Contain at least 1 capital letter</li>
              <li>Contain at least 1 number or symbol</li>
            </ul>
          </Grid>
        </Grid>
        <div id="controlContainer" data-testid="controlContainer">
          <div
            className="initial-password--email"
            id="divEmail"
            data-testid="divEmail"
          >
            <PersonIcon data-testid="svgPerson" />
            <span className="initial-password--email--text">{email}</span>
          </div>
          <SsoTextField
            labelProps={{ labelText: "Temporary Password" }}
            validationForOnBlur={[Validations.required]}
            textFieldProps={{
              debounceInterval: 0,
              clearable: true,
              textProps: {
                autoComplete: "new-password",
                id: "txtTempPassword",
                placeholder: "Enter temporary password",
                autoFocus: true,
                value: tempPassword,
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  setTempPassword(e.target.value),
                InputProps: {
                  disabled: inProgress
                }
              }
            }}
          />
          <SsoTextField
            labelProps={{ labelText: "Password" }}
            validationForOnBlur={[Validations.validPassword]}
            validationerror={(validationError: boolean) =>
              setHasPasswordValidationError(validationError)
            }
            textFieldProps={{
              debounceInterval: 0,
              textProps: {
                autoComplete: "new-password",
                id: "txtPassword",
                placeholder: "Enter your new password",
                value: password,
                type: viewPassword ? "text" : "password",
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.target.value),
                InputProps: {
                  disabled: inProgress,
                  endAdornment:
                    password.length && !inProgress ? (
                      <SsoPasswordAdornment
                        showPassword={viewPassword}
                        viewSetter={setViewPassword}
                      />
                    ) : null
                }
              }
            }}
          />
          <SsoTextField
            labelProps={{ labelText: "Confirm Password" }}
            validationForOnBlur={[
              Validations.passwordsMatch(password),
              Validations.validPassword
            ]}
            validationerror={(validationError: boolean) =>
              setHasConfirmPasswordValidationError(validationError)
            }
            textFieldProps={{
              debounceInterval: 0,
              textProps: {
                id: "txtConfirmPassword",
                autoComplete: "new-password",
                placeholder: "Confirm your new password",
                type: viewConfirmPassword ? "text" : "password",
                value: confirmedPassword,
                onChange: (e: ChangeEvent<HTMLInputElement>) =>
                  setConfirmedPassword(e.target.value),
                InputProps: {
                  disabled: inProgress,
                  endAdornment:
                    confirmedPassword.length && !inProgress ? (
                      <SsoPasswordAdornment
                        showPassword={viewConfirmPassword}
                        viewSetter={setViewConfirmPassword}
                      />
                    ) : null
                }
              }
            }}
          />
          <FelProgressButton
            buttonProps={{
              disabled: isButtonDisabled,
              type: "button",
              className: "btn next-btn initial-password--button",
              variant: "contained",
              color: "primary",
              onClick: onSubmit
            }}
            inProgress={inProgress}
          >
            Submit and Sign in
          </FelProgressButton>
        </div>
      </div>
    </Paper>
  );
};
