import React, { useContext, useEffect, useState } from "react";
import { Paper, Grid, Box } from "@material-ui/core";
import { SsoTextField } from "..";
import InfoIcon from "@material-ui/icons/Info";
import { FelProgressButton } from "front-end-lib";
import { AppContext, SingletonSvcsContext } from "../../contexts";
import { RouteComponentProps } from "react-router-dom";
import { ISigninResponse, Validations } from "../../model";

import "./_verifyEmail.scss";

export interface IVerifyEmailProps
  extends RouteComponentProps<
    {},
    {},
    Partial<{
      email: string;
      cognitoUsername: string;
      verification: boolean;
      verifyEmail: boolean;
    }>
  > {}

export const VerifyEmail = (props: IVerifyEmailProps) => {
  const { user } = useContext(SingletonSvcsContext);

  const { history, location } = props;
  if (!location.state) {
    history.push("/");
  }
  const { displayNotification: openSnackbar } = useContext(AppContext);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [emailDirty, setEmailDirty] = useState(false);
  const [email, setEmail] = useState(location.state?.email ?? "");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [inProgress, setInProgress] = React.useState(false);
  const [hasEmailValidationError, setHasEmailValidationError] = useState(false);

  useEffect(() => {
    setSubmitDisabled(
      inProgress ||
        hasEmailValidationError ||
        (emailDirty && email !== confirmEmail)
    );
  }, [confirmEmail, email, hasEmailValidationError, emailDirty, inProgress]);

  console.info(props);

  const onClick = async (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setInProgress(true);
    const payload = { email: email, verifyEmail: confirmEmail };
    if (!confirmEmail) payload.verifyEmail = email;

    try {
      const response = (await user.confirmEmailAsync(
        location.state.cognitoUsername!,
        payload.email,
        payload.verifyEmail
      )) as ISigninResponse;

      setInProgress(false);

      history.push({
        pathname: "/verify-email-password",
        search: `${new URLSearchParams({
          cognitoUserName: response.cognitoUserName,
          email: response.credential
        }).toString()}`,
        state: { verification: true, verifyEmail: true }
      });
    } catch (e) {
      setInProgress(false);
      if (e.message) openSnackbar(e.message.replace(/"(.+)"/g, "$1"));
      else openSnackbar("Failed to fetch");
    }
  };

  return (
    <form>
      <Paper elevation={2} className="sso-verify-email">
        <h1 className="sso-verify-email--header" data-testid="hdrVerifyEmail">
          Verify Email Address
        </h1>
        <div className="sso-verify-email--container">
          <Grid
            container
            direction="row"
            className="sso-verify-email--email-info"
            data-testid="divInfo"
          >
            <Grid item xs={2}>
              <Box display="flex" alignItems="center" justifyContent="center">
                <Paper elevation={0}>
                  <InfoIcon
                    data-testid="svgInfo"
                    color="inherit"
                    classes={{ root: "sso-verify-email--icon--email" }}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={10} className="sso-verify-email--content">
              <div
                className="sso-verify-email--content--email-text"
                data-testid="email-text"
              >
                {`Please confirm your email address below.  This will be your login and the main email for communications.  If the email address shown is incorrect, you may update the email to the correct one below.  This is a one-time confirmation.`}
              </div>
            </Grid>
          </Grid>
          <SsoTextField
            labelProps={{ labelText: "Email" }}
            validationForOnBlur={[
              Validations.required,
              Validations.validEmailWithSpaces
            ]}
            textFieldProps={{
              textProps: {
                id: "txtEmail",
                placeholder: "Enter your email address",
                value: email,
                onChange: (e) => {
                  setEmail(e.target.value);
                  setEmailDirty(true);
                }
              }
            }}
          ></SsoTextField>
          {emailDirty ? (
            <Grid>
              <SsoTextField
                labelProps={{ labelText: "Retype Email" }}
                validationForOnBlur={[
                  Validations.validEmailWithSpaces,
                  Validations.emailsMatch(email)
                ]}
                validationerror={(validationError: boolean) =>
                  setHasEmailValidationError(validationError)
                }
                textFieldProps={{
                  textProps: {
                    id: "txtConfirmEmail",
                    placeholder: "Enter your email address",
                    value: confirmEmail,
                    onChange: (e) => setConfirmEmail(e.target.value)
                  }
                }}
              ></SsoTextField>
            </Grid>
          ) : (
            ""
          )}
          <FelProgressButton
            buttonProps={{
              type: "submit",
              disabled: submitDisabled,
              className: "btn next-btn sso-verify-email--button",
              variant: "contained",
              color: "primary",
              onClick: onClick
            }}
            inProgress={inProgress}
          >
            Submit and Continue
          </FelProgressButton>
        </div>
      </Paper>
    </form>
  );
};
